import { useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BTN_LOGIN_URL, BTN_REGISTER_URL, DOMAIN_URL_MAP, FallbackRefCode } from "../constants";
import { addParams } from "../utils";
import useFbcid from "./useFbcid";

const useUrls = () => {
  const [urls, setUrls] = useState(["", "", FallbackRefCode[0]]);
  const [params] = useSearchParams();
  const { fbcid } = useFbcid();

  useLayoutEffect(() => {
    const hostname = window.location.hostname.replace(/^www\./, "");

    let loginUrl = DOMAIN_URL_MAP[hostname]?.login ?? BTN_LOGIN_URL;
    let registerUrl = DOMAIN_URL_MAP[hostname]?.register ?? BTN_REGISTER_URL;

    const code = params.has("code") ? params.get("code") ?? undefined : undefined;

    const urlParams = {
      fbcid: fbcid ? fbcid : undefined,
      id: code,
    };

    loginUrl = addParams(loginUrl, urlParams);
    registerUrl = addParams(registerUrl, urlParams);

    setUrls([loginUrl, registerUrl, code ?? ""]);
  }, [params, fbcid]);

  return {
    loginUrl: urls[0] as string,
    registerUrl: urls[1] as string,
    refCode: urls[2] as string,
  };
};

export default useUrls;
